<template>
  <div class="ml-8 mr-8 mt-5 mb-5">
    <div class="grid">
      <div class="col-12 food-form-title">{{ title }}</div>
      <div class="food-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="food-form-sub-title">Promotion Code Detail</div>
            </div>
            <div class="col-8">
              <TextInput
                v-model="vmodel.code"
                :validation="v$.vmodel.code"
                placeholder="Promotion Code"
                sectionClass="field mt-1"
                id="code"
                label="Promotion Code"
              />
              <TextAreaRequire
                v-model="vmodel.description"
                :validation="v$.vmodel.description"
                placeholder="Description"
                sectionClass="field mt-1"
                id="description"
                label="Description"
                maxlength="500"
              />
              <div class="grid">
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.type"
                    :validation="v$.vmodel.type"
                    sectionClass="field mt-1"
                    id="type"
                    :options="typeOptions"
                    optionLabel="label"
                    optionValue="value"
                    label="Discount Type"
                  />
                </div>
                <div class="col-6">
                  <InputGroup
                    v-model="vmodel.discount"
                    :validation="v$.vmodel.discount"
                    placeholder="Discount"
                    sectionClass="field mt-1"
                    id="discount"
                    label="Discount"
                    :min="1"
                    v-if="vmodel.type == 'Fixed'"
                  />
                  <InputPercent
                    v-model="vmodel.discount"
                    :validation="v$.vmodel.discount"
                    placeholder="Discount"
                    sectionClass="field mt-1"
                    id="discount"
                    label="Discount"
                    v-else
                  />
                </div>
              </div>
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="food-form-button"
                  v-if="!isEditMode"
                  @click="createPromotionCode"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="food-form-button"
                  v-else
                  @click="createPromotionCode"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter div-class="footer-text" />
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api.service.js";
import { required, maxLength, minLength, minValue } from "@vuelidate/validators";
import DateFormat from "@/libs/DateFormat";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      title: "Promotion Code / Add",
      isEditMode: false,
      vmodel: {
        code: "",
        description: "",
        type: "Fixed",
        discount: 1,
      },
      submitted: false,
      typeOptions: [
        { label: "Fixed", value: "Fixed" },
        { label: "Percentage", value: "Percentage" },
      ],
    };
  },
  validations() {
    return {
      vmodel: {
        code: { required, min: minLength(3), max: maxLength(15) },
        description: { required },
        type: { required },
        discount: { required, minValue: minValue(1) },
      },
    };
  },
  created() {
    this.formatDate = DateFormat.formatDate;
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Promotion Code / Edit";
      this.isEditMode = true;
      this.getPromotionCodeById();
    }
  },
  methods: {
    async createPromotionCode() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          code: this.vmodel.code,
          description: this.vmodel.description,
          type: this.vmodel.type,
          discount: this.vmodel.discount,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/promontioncode", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "PromotionCode" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/promontioncode", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "PromotionCode" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getPromotionCodeById() {
      APIService.getById("/promontioncode", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.code = response.data.data.code;
            this.vmodel.description = response.data.data.description;
            this.vmodel.type = response.data.data.type;
            this.vmodel.discount = response.data.data.discount;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>
