<template>
  <div class="ml-8 mr-8 mt-5 mb-5">
    <div class="grid">
      <div class="col-12 food-form-title">{{ title }}</div>
      <div class="food-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="food-form-sub-title">Terms & Conditions Page</div>
            </div>
            <div class="col-8">
                <span class="content">Content</span>
                <EditorRequire
                  v-model="vmodel.content"
                />
                <div class="mt-4">
                <PrimeButton
                  label="Edit"
                  class="food-form-button"
                  @click="onEdit"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter div-class="footer-text" />
</template>

<script>
import APIService from "@/services/api.service.js";

export default {
  data() {
    return {
      title: 'CMS Page Management / Terms & Conditions Page / Edit',
      vmodel: {
        content: '',
      }
    }
  },
  mounted() {
    if (this.$route.name) {
      this.getTermsByType();
    }
  },
  methods: {
    onEdit() {
      let payload = {
        content: this.vmodel.content,
      }
      if (this.$route.name) {
      APIService.patch("/cmspages", payload, this.$route.name)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
      }
    },

    getTermsByType() {
      APIService.getById("/cmspages", this.$route.name)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.content = response.data.data.content
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    }
  }
}
</script>