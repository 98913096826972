/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import MainFooter from '@/components/layout/main-footer.vue'
import store from './store';

import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Calendar from 'primevue/calendar';
import Chip from 'primevue/chip';
import FileUpload from 'primevue/fileupload';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Card from 'primevue/card';
import Editor from 'primevue/editor';
import EditorRequire from "@/components/form-control/editor.vue"
import InputTextRequire from '@/components/form-control/input-text.vue';
import InputNumberRequire  from '@/components/form-control/input-number.vue'
import TextAreaRequire from '@/components/form-control/text-area.vue';
import DropDownRequire from '@/components/form-control/drop-down.vue';
import InputDate from '@/components/form-control/input-date.vue';
import InputGroup from '@/components/form-control/input-group.vue'
import InputPercent from '@/components/form-control/input-group-percentage.vue'
import InputDateFormat from '@/components/form-control/input-date-right.vue'
import ConfirmPopup from '@/components/modal-popup/confirm-popup.vue';
import TextInput from '@/components/form-control/text-input.vue';

import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeflex/primeflex.min.css'
import 'primeicons/primeicons.css'                           //icons
import '@/assets/css/global.css'

const app = createApp(App)
app.use(ToastService)
app.use(ConfirmationService)
app.use(store)
app.use(PrimeVue)

app.component('MainFooter', MainFooter)

app.component('Editor', Editor)
app.component('ConfirmPopup', ConfirmPopup)
app.component('PrimeToast', Toast)
app.component('FileUpload', FileUpload)
app.component('Chip', Chip)
app.component('Calendar', Calendar)
app.component('InputText', InputText)
app.component('InputNumber', InputNumber)
app.component('PrimeButton', Button)
app.component('Dropdown', Dropdown)
app.component('Textarea', Textarea)
app.component('DataTable', DataTable)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('Card', Card)
app.component('EditorRequire', EditorRequire)
app.component('InputTextRequire', InputTextRequire)
app.component('InputNumberRequire', InputNumberRequire)
app.component('TextAreaRequire', TextAreaRequire)
app.component('DropDownRequire', DropDownRequire)
app.component('InputDate', InputDate)
app.component('InputGroup', InputGroup)
app.component('InputPercent', InputPercent)
app.component('InputDateFormat', InputDateFormat)
app.component('Column', Column)
app.component('TextInput', TextInput)

app.use(router).mount('#app')
