import DateFormat from "@/libs/DateFormat"

export default class Member {
    constructor(data = {}) {
        return {
            id: data.id || '',
            userid: data.userid || '',
            username: data.username || '',
            avatar: data.avatar || '',
            email: data.email || '',
            phoneno: data.phoneno || '',
            birthdate: data.birthdate || '',
            createdby: data.createdby || '',
            createddate: DateFormat.formatDate(data.createddate) || '',
        }
    }
}