import DateFormat from "@/libs/DateFormat"
import ItemFactory from "@/factories/item";

export default class OrderManagement {
    constructor(data = {}) {
        return {
            id: data.id || '',
            orderno: data.orderno || '',
            orderdate: DateFormat.formatDate(data.orderdate) || '',
            memberid: data.memberid || '',
            pickuptime: data.pickuptime || '',
            promocodeid: data.promocodeid || '',
            promocode: data.promocode || '',
            subtotal: data.subtotal || '',
            discount: data.discount || 0,
            total: data.total || '',
            paymentmethod: data.paymentmethod || '',
            status: data.status || '',
            items: ItemFactory.createFromJsonArray(data.items) || '',
        }
    }
}