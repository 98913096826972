import DateFormat from "@/libs/DateFormat";

export default class ItemCategory {
    constructor(data = {}) {
        return {
            id: data.id || '',
            category: data.category || '',
            createdby: data.createdby || '',
            createddate: DateFormat.formatDate(data.createddate) || '',
        }

    }
}