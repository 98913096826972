import moment from 'moment';

export default {
formatDate(data, invalid = null) {
    if (!data) {
      return invalid
    }

    return moment(data).format('YYYY-MM-DD')
  }
}