<template>
  <div class="ml-8 mr-8 mt-5 mb-5">
    <div class="grid">
      <div class="col-12 food-form-title">{{ title }}</div>
      <div class="food-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="food-form-sub-title">Member Detail</div>
            </div>
            <div class="col-8">
              <InputTextRequire
                v-model="vmodel.userid"
                :validation="v$.vmodel.userid"
                sectionClass="field mt-1"
                id="userid"
                label="User ID (Auto Generate)"
                class="disable-text-field"
                :disabled="true"
              />
              <div class="grid">
                <div class="col-2">
                  <label for="span" class="form-label">Avatar</label>
                  <span id="span"
                    ><img
                      :src="getAvatarPath(vmodel.avatar)"
                      alt=""
                      class="avatar-img"
                      height="80"
                      width="80"
                  /></span>
                </div>
                <FileUpload
                  ref="photo"
                  name="photo"
                  accept=".png, .jpg, .jpeg"
                  @select="onAdvancedUpload($event)"
                  v-show="false"
                  :aria-describedby="`photo-help`"
                />
                <div
                  v-if="
                    v$.vmodel.avatar.$error &&
                    v$.vmodel.avatar.required &&
                    v$.vmodel.avatar.required.$invalid
                  "
                  :id="`photo-help`"
                  class="p-error text-left mt-2"
                >
                  {{
                    v$.vmodel.avatar.required.$message.replace(
                      "Value",
                      "Avatar"
                    )
                  }}
                </div>
                <div class="col-3 mt-3">
                  <PrimeButton
                    label="Upload"
                    class="upload-button"
                    @click="openFileUpload"
                  />
                </div>
              </div>
              <TextInput
                v-model="vmodel.username"
                :validation="v$.vmodel.username"
                placeholder="Username"
                sectionClass="field mt-1"
                id="username"
                label="Username"
                maxlength="50"
              />
              <TextInput
                v-model="vmodel.email"
                :validation="v$.vmodel.email"
                placeholder="Email"
                sectionClass="field mt-1"
                id="email"
                type="email"
                label="Email"
                maxlength="50"
              />
              <TextInput
                v-model="vmodel.phoneno"
                :validation="v$.vmodel.phoneno"
                placeholder="Phone"
                sectionClass="field mt-1"
                id="phone"
                type="phone"
                label="Phone"
                maxlength="8"
                minlength="8"
              />
              <InputDate
                v-model="vmodel.birthdate"
                :validation="v$.vmodel.birthdate"
                placeholder="Birthday"
                sectionClass="field mt-1"
                id="birthday"
                label="Birthday"
              />
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="food-form-button"
                  v-if="!isEditMode"
                  @click="createMember"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="food-form-button"
                  v-else
                  @click="createMember"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter div-class="footer-text" />
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api.service.js";
import {
  required,
  email,
  maxLength,
  minLength,
  numeric,
} from "@vuelidate/validators";
import DateFormat from "@/libs/DateFormat";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      title: "Member Management / Add",
      isEditMode: false,
      photo: "",
      vmodel: {
        userid: "",
        avatar: "default-avatar.png",
        username: "",
        email: "",
        phoneno: "",
        birthdate: "",
      },
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel: {
        userid: {},
        avatar: { required },
        username: { required, max: maxLength(50) },
        email: { required, email, max: maxLength(50) },
        phoneno: { max: maxLength(8), min: minLength(8), numeric },
        birthdate: {},
      },
    };
  },
  created() {
    this.formatDate = DateFormat.formatDate;
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Member Management / Edit";
      this.isEditMode = true;
      this.getMemberById();
    } else {
      this.vmodel.userid = Math.floor(
        1000000000 + Math.random() * 9000000000
      ).toString();
    }
  },
  methods: {
    openFileUpload() {
      this.$refs.photo.choose();
    },
    async createMember() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          userid: this.vmodel.userid,
          avatar: this.vmodel.avatar,
          username: this.vmodel.username,
          email: this.vmodel.email,
          phoneno: this.vmodel.phoneno,
          birthdate: this.vmodel.birthdate,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/members", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "MemberManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/members", payload)
            .then((response) => {
              console.log(1);
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "MemberManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getMemberById() {
      APIService.getById("/members", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.userid = response.data.data.userid;
            this.vmodel.avatar = response.data.data.avatar;
            this.vmodel.username = response.data.data.username;
            this.vmodel.email = response.data.data.email;
            this.vmodel.phoneno = response.data.data.phoneno;
            this.vmodel.birthdate = DateFormat.formatDate(
              response.data.data.birthdate
            );
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "MemberManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getAvatarPath(avatarname) {
      return process.env.VUE_APP_PROFILE_IMAGE_BASE_PATH + avatarname;
    },
    onAdvancedUpload(fileObj) {
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid image",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image size should be less then 1 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        let formData = new FormData();
        formData.append("file", fileObj.files[0]);
        APIService.post("/members/avatar", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              // console.log(response.data, 'response.data.data.name');
              this.vmodel.avatar = response.data.data.name;
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.photo.clear();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-img {
  border: 1px solid #ccc9d6;
  border-radius: 80px;
}

::v-deep(.disable-text-field) {
  .p-inputtext {
    background: rgba(204, 201, 214, 0.4);
  }
}
</style>