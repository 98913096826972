import DateFormat from "@/libs/DateFormat"

export default class FoodPassManagement {
    constructor(data = {}) {
        return {
            id: data.id || '',
            image: data.image || '',
            name: data.name || '',
            mealqty: data.mealqty || '',
            expirydate: DateFormat.formatDate(data.expirydate) || '',
            detail: data.detail || '',
            price: data.price || '',
            createdby: data.createdby || '',
            createddate: DateFormat.formatDate(data.createddate) || '',
        }
    }
}