<template>
  <div :class="sectionClass">
    <label :for="id" class="form-label">{{ label }}</label>
    <InputText
      :id="id"
      :type="type"
      :value="modelValue"
      :disabled="disabled"
      :placeholder="placeholder"
      :class="{
          'p-invalid': validation.$error,
        }"
        :aria-describedby="`${id}-help`"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <span
      v-if="
        validation.$error && validation.required && validation.required.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.required.$message.replace("Value", this.label) }}
    </span>
    <span
      v-else-if="validation.$error && validation.email && validation.email.$invalid"
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.email.$message }}
    </span>
    <span
      v-else-if="validation.$error && validation.min && validation.min.$invalid"
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.min.$message }}
    </span>
    <span
      v-else-if="validation.$error && validation.max && validation.max.$invalid"
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.max.$message }}
    </span>
    <span
      v-else-if="validation.$error && validation.sameAs && validation.sameAs.$invalid"
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.sameAs.$message }}
    </span>
    <span
      v-else-if="validation.$error && validation.numeric && validation.numeric.$invalid"
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.numeric.$message }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    validation: {
      type: Object,
      required: true,
    },
    minlength: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
};
</script>