<template>
  <div class="m-5">
    <DataTable
      :value="promoCode"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="code"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 list-header">Promotion Code</div>
          <div class="col-6 text-right ml-4 list-header">
            <PrimeButton
              label="Add"
              class="add-button mr-3"
              icon="add-icon"
              @click="gotoCreatePromoCode"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch"/>
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="code" header="Promotion Code" sortable="true"></Column>
      <Column field="description" header="Description" sortable="true"></Column>
      <Column field="type" header="Type" sortable="true"></Column>
      <Column field="discount" header="Discount" sortable="true"></Column>
      <Column field="createdby" header="Created By" sortable="true"></Column>
      <Column field="createddate" header="Created At" sortable="true"></Column>
      <Column field="action" header="Action" class="col-width">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              label="Edit"
              icon="edit-icon"
              class="edit-button"
              @click="gotoPromoCodeEdit(data.data.id)"
            />
            <PrimeButton
              label="Delete"
              icon="delete-icon"
              class="delete-button"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <MainFooter div-class="footer-text" />
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deletePromoCode"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api.service";
import PromotionCodeFactory from "@/factories/promotioncode";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      PromotionCodeForDelete: "",
      showPopup: false,
      promoCode: [],
    };
  },
  mounted() {
    this.getPromoCodeList();
  },
  methods: {
    gotoPromoCodeEdit(promocodeId) {
      this.$router.push({ name: "PromotionCodeEdit", params: { id: promocodeId } });
    },
    gotoCreatePromoCode() {
      this.$router.push({ name: "PromotionCodeCreate" });
    },
    showDeletePopup(promocodeId) {
      this.PromotionCodeForDelete = promocodeId;
      this.showPopup = true;
    },
    deletePromoCode() {
      APIService.delete(`/promontioncode/delete/${this.PromotionCodeForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getPromoCodeList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    getPromoCodeList() {
      this.promoCode=[];
      APIService.get(
        `/promontioncode/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.promoCode = PromotionCodeFactory.createFromJsonArray(response.data.data);
        }
      });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getPromoCodeList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createdAt") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getPromoCodeList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getPromoCodeList();
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>