<template>
  <div class="m-5">
    <DataTable
      :value="fooditems"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="item"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 list-header">Food Pass Management</div>
          <div class="col-6 text-right ml-4 list-header">
            <PrimeButton
              label="Add"
              class="add-button mr-3"
              icon="add-icon"
              @click="gotoCreateFoodPass"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="name" header="Food pass" sortable="true" class="col-width-item">
        <template #body="row">
          <div class="grid">
            <div class="col-4 mt-2">
              <img :src="getImagePath(row.data.image)" alt="" class="img-item-square" />
            </div>
            <div class="col-8 name">
                <span>{{row.data.name}}</span>
            </div>
          </div>
        </template>
      </Column>
      <Column field="detail" header="Details" sortable="true"></Column>
      <Column field="mealqty" header="Meal Qty" sortable="true"></Column>
      <Column field="expirydate" header="Expiry Date" sortable="true"></Column>
      <Column field="price" header="Price" sortable="true">
        <template #body="row">
          <span>$ {{row.data.price}}</span>
        </template>
      </Column>
      <Column field="createdby" header="Created By" sortable="true"></Column>
      <Column field="createddate" header="Created At" sortable="true"></Column>
      <Column field="action" header="Action" class="col-width">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              label="Edit"
              icon="edit-icon"
              class="edit-button"
              @click="gotoFoodPassEdit(data.data.id)"
            />
            <PrimeButton
              label="Delete"
              icon="delete-icon"
              class="delete-button"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <MainFooter div-class="footer-text" />
    <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteFoodItem"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
  </div>
</template>

<script>
import APIService from "@/services/api.service.js";
import FoodPassFactory from "@/factories/foodpass";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      foodIdForDelete: "",
      showPopup: false,
      fooditems: [
        {
          image: "",
          name: "",
          detail: "",
          mealqty: "",
          expirydate: "",
          price: "",
          createdby: "",
          createddate: "",
        },
      ],
    };
  },
  mounted() {
    this.getFoodPass();
  },
  methods: {
    gotoFoodPassEdit(fooditemId) {
      this.$router.push({ name: "FoodPassManagementEdit", params: {id:fooditemId} });
    },
    gotoCreateFoodPass() {
      this.$router.push({ name: "FoodPassManagementCreate" });
    },
    showDeletePopup(fooditemId) {
      this.foodIdForDelete = fooditemId;
      this.showPopup = true;
    },
    getImagePath(name) {
      return process.env.VUE_APP_FOOD_PASS_IMAGE_BASE_PATH + name;
    },
    deleteFoodItem() {
      APIService.delete(`/foodpassmanagement/delete/${this.foodIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getFoodPass();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    getFoodPass() {
      this.fooditems = [];
      APIService.get(
        `/foodpassmanagement/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.fooditems = FoodPassFactory.createFromJsonArray(response.data.data);
        }
      });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getFoodPass();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getFoodPass();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getFoodPass();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";

.name{
  margin-top: 21px;
}
</style>