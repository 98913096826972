<template>
  <div class="m-5">
    <DataTable
      :value="items"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="name"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 list-header">Item (Menu) Management</div>
          <div class="col-6 text-right ml-4 list-header">
            <PrimeButton
              label="Add"
              class="add-button mr-3"
              icon="add-icon"
              @click="gotoCreateItem"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="name" header="Item" sortable="true" class="col-width-item">
        <template #body="row">
          <div class="grid">
            <div class="col-4 mt-2">
              <img
                :src="getImagePath(row.data.image)"
                alt=""
                class="img-item-square"
              />
            </div>
            <div class="col-8 name-margin">
              <span>{{ row.data.name }}</span>
            </div>
          </div>
        </template>
      </Column>
      <Column field="category" header="Category" sortable="true">
        <template #body="row">
          <Chip :label="row.data.category" class="chip-style" />
        </template>
      </Column>
      <Column field="detail" header="Item Detail" sortable="true"></Column>
      <Column field="price" header="Price" sortable="true">
        <template #body="row">
          <span>$ {{ row.data.price }}</span>
        </template>
      </Column>
      <Column field="createdby" header="Created By" sortable="true"></Column>
      <Column field="createddate" header="Created At" sortable="true"></Column>
      <Column field="action" header="Action" class="col-width">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              label="Edit"
              icon="edit-icon"
              class="edit-button"
              @click="gotoItemEdit(data.data.id)"
            />
            <PrimeButton
              label="Delete"
              icon="delete-icon"
              class="delete-button"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <MainFooter div-class="footer-text" />
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteItem"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api.service.js";
import ItemManagementFactory from "@/factories/itemmanagement";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      itemIdForDelete: "",
      showPopup: false,
      items: [],
    };
  },
  mounted() {
    this.getItemMenu();
  },
  methods: {
    gotoItemEdit(itemId) {
      this.$router.push({ name: "ItemManagementEdit", params: { id: itemId } });
    },
    gotoCreateItem() {
      this.$router.push({ name: "ItemManagementCreate" });
    },
    showDeletePopup(itemId) {
      this.itemIdForDelete = itemId;
      this.showPopup = true;
    },
    getImagePath(name) {
      return process.env.VUE_APP_MENU_IMAGE_BASE_PATH + name;
    },
    deleteItem() {
      APIService.delete(`/itemmenu/delete/${this.itemIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getItemMenu();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    getItemMenu() {
      this.items = [];
      APIService.get(
        `/itemmenu/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.items = ItemManagementFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getItemMenu();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getItemMenu();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getItemMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";

.name-margin {
  margin-top: 21px;
}
</style>