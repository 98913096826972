<template>
  <div class="ml-8 mr-8 mt-5 mb-5">
    <div class="grid">
      <div class="col-12 food-form-title">{{ title }}</div>
      <div class="food-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="food-form-sub-title">Order Detail</div>
            </div>
            <div class="col-8">
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.orderno"
                    :validation="v$.vmodel.orderno"
                    placeholder="Order #"
                    sectionClass="field mt-1"
                    id="order"
                    label="Order #"
                    :disabled="true"
                    class="disable-text-field"
                  />
                </div>
                <div class="col-6">
                  <InputDate
                    v-model="vmodel.orderdate"
                    :validation="v$.vmodel.orderdate"
                    placeholder="Order Date"
                    sectionClass="field mt-1"
                    id="orderdate"
                    label="Order Date"
                    v-if="!isEditMode"
                  />
                  <InputDate
                    v-model="vmodel.orderdate"
                    :validation="v$.vmodel.orderdate"
                    placeholder="Order Date"
                    sectionClass="field mt-1"
                    :disabled="true"
                    id="orderdate"
                    label="Order Date"
                    v-else
                  />
                </div>
              </div>
              <DropDownRequire
                v-model="vmodel.memberid"
                :validation="v$.vmodel.memberid"
                placeholder="User"
                sectionClass="field mt-1"
                id="user"
                label="User"
                :options="memberList"
                optionLabel="username"
                optionValue="id"
              />
              <DropDownRequire
                v-model="vmodel.pickuptimeid"
                :validation="v$.vmodel.pickuptimeid"
                placeholder="Pick-up Time"
                sectionClass="field mt-1"
                id="pickuptime"
                label="Pick-up Time"
                :options="pickuptimeList"
                optionLabel="pickuptime"
                optionValue="id"
              />
              <label for="box" class="form-label field">Item List</label>
              <div id="box" class="box">
                <div
                  class="grid"
                  v-for="(item, index) in vmodel.items"
                  :id="item.id"
                  :key="item.id"
                >
                  <div class="col-5">
                    <DropDownRequire
                      v-model="vmodel.items[index].itemid"
                      :validation="
                        v$.vmodel.items.$each.$response.$errors[index].itemid[0]
                      "
                      placeholder="Item Name"
                      sectionClass="field"
                      id="item"
                      label="Item Name"
                      labelClass="hidden"
                      :options="itemList"
                      optionLabel="name"
                      optionValue="id"
                      class="width-dropdown"
                      :customfield="true"
                      :validated="validated"
                      @update:modelValue="getItemListDetails(index)"
                    />
                  </div>
                  <div class="col-3">
                    <div class="quantity-field">
                      <button
                        class="value-button decrease-button"
                        @click="decrement(index)"
                      >
                        <span class="minus-icon"></span>
                      </button>
                      <InputText
                        v-model="vmodel.items[index].qty"
                        class="number"
                        labelClass="hidden"
                        :class="{
                          'p-invalid':
                            validated &&
                            v$.vmodel.items.$each.$response.$errors[index]
                              .qty[0],
                        }"
                      />
                      <!-- <div class="number">{{ vmodel.items[index].qty === 0 ? 'Qty' : vmodel.items[index].qty }}</div> -->
                      <button
                        class="value-button increase-button"
                        @click="increment(index)"
                      >
                        <span class="plus-icon"></span>
                      </button>
                    </div>
                    <span
                      v-if="
                        validated &&
                        v$.vmodel.items.$each.$response.$errors[index].qty[0]
                      "
                      class="p-error text-left mt-2"
                    >
                      Minimum 1 Qty required
                    </span>
                  </div>
                  <!-- <div class="col-3 box-add-sub field"></div> -->
                  <div class="col-3">
                    <InputGroup
                      v-model="vmodel.items[index].price"
                      :validation="
                        v$.vmodel.items.$each.$response.$errors[index].price[0]
                      "
                      placeholder="Price"
                      sectionClass="field"
                      labelClass="hidden"
                      id="price"
                      label="Price"
                      :customfield="true"
                      :validated="validated"
                    />
                  </div>
                  <div class="col-1 field">
                    <PrimeButton
                      icon="bin-icon"
                      class="bin-button"
                      @click="removeItem(index)"
                    />
                  </div>
                </div>
                <div class="grid">
                  <div class="col-4 field">
                    <PrimeButton
                      label="ADD NEW ITEM"
                      icon=""
                      class="add-item-button"
                      @click="repeateAgain"
                    />
                  </div>
                </div>
              </div>
              <div class="box2">
                <div class="grid">
                  <div class="col-6 field order-form-label">Subtotal</div>
                  <div
                    class="
                      col-6
                      field
                      form-label
                      card-font-digit
                      margin-for-list
                    "
                  >
                    $ {{ calculateSummary.subTotal.toFixed(1) }}
                  </div>
                </div>
                <div class="grid">
                  <div class="col-6 mt-2">
                    <InputText
                      v-model="vmodel.promocode"
                      id="promotioncode"
                      placeholder="Promotion Code"
                    />
                  </div>
                  <div class="mt-4">
                    <PrimeButton
                      label="Apply"
                      class="apply-button"
                      @click="getPromocode"
                    />
                  </div>
                  <div
                    v-if="vmodel.promocode !== ''"
                    class="
                      col-6
                      field
                      form-label
                      promo-code-font
                      mt-4
                      margin-for-list
                    "
                  >
                    -$ {{ discountprice }}
                  </div>
                </div>
                <div class="grid mt-1">
                  <div class="col-6 field order-form-label">Order Total</div>
                  <div
                    class="
                      col-6
                      field
                      form-label
                      card-font-digit
                      margin-for-list
                    "
                  >
                    $ {{ calculateSummary.orderTotal.toFixed(1) }}
                  </div>
                </div>
              </div>
              <div v-if="isEditMode == true">
                <InputTextRequire
                  v-model="vmodel.paymentmethod"
                  :validation="v$.vmodel.paymentmethod"
                  sectionClass="field mt-4"
                  id="payment"
                  label="Payment Method"
                  :disabled="true"
                  class="disable-text-field"
                />
              </div>
              <div class="grid mt-4 justify-content-between">
                <PrimeButton
                  label="Add"
                  class="food-form-button"
                  v-if="!isEditMode"
                  @click="createOrder"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="food-form-button"
                  v-else
                  @click="createOrder"
                ></PrimeButton>
                <PrimeButton
                  label="Cancel"
                  class="cancel-button-ordermanagement"
                  v-if="isEditMode"
                  @click="gotoOrderList"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter div-class="footer-text" />
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api.service.js";
import { required, minValue, helpers } from "@vuelidate/validators";
import DateFormat from "@/libs/DateFormat";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      pickuptimeList: [],
      memberList: [],
      itemList: [],
      isEditMode: false,
      title: "Order Management / Add",
      discounttype: "",
      discountprice: 0,
      vmodel: {
        orderno: "",
        orderdate: "",
        memberid: "",
        pickuptimeid: "",
        promocodeid: "",
        promocode: "",
        subtotal: "",
        discount: "",
        total: "",
        paymentmethod: "Stripe",
        items: [
          {
            itemid: "",
            itemname: "",
            categoryid: "",
            categoryname: "",
            qty: 0,
            price: 0,
          },
        ],
      },
      submitted: false,
      validated: false,
    };
  },
  validations() {
    return {
      vmodel: {
        orderno: { required },
        orderdate: { required },
        memberid: { required },
        pickuptimeid: { required },
        subtotal: { required },
        discount: { required },
        total: { required },
        paymentmethod: { required },
        items: {
          $each: helpers.forEach({
            itemid: { required },
            itemname: { required },
            categoryid: { required },
            categoryname: { required },
            qty: { required, minValue: minValue(1) },
            price: { required, minValue: minValue(1) },
          }),
        },
      },
    };
  },
  computed: {
    calculateSummary() {
      let subTotal = 0;
      const TotalPrice = this.vmodel.items.map((x) => parseFloat(x.price));
      subTotal = TotalPrice.reduce((partialSum, a) => partialSum + a, 0);

      let orderTotal = 0;

      if (this.discounttype === "Fixed") {
        orderTotal = subTotal - this.discountprice;
      } else if (
        this.discountprice == null ||
        this.discountprice == undefined ||
        this.discountprice == ""
      ) {
        orderTotal = subTotal;
      } else {
        orderTotal = (subTotal * (100 - this.discountprice)) / 100;
      }

      return { subTotal, orderTotal };
    },
  },
  created() {
    this.formatDate = DateFormat.formatDate;
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Order Management / Edit";
      this.isEditMode = true;
      this.getOrderById();
    } else {
      this.vmodel.orderno = Math.floor(
        10000000 + Math.random() * 90000000
      ).toString();
    }
    this.getMember();
    this.getPickupTime();
    this.getItem();
  },

  methods: {
    gotoOrderList() {
      this.$router.push({ name: "OrderManagement" });
    },
    increment(index) {
      this.vmodel.items[index].qty =
        parseFloat(this.vmodel.items[index].qty) + 1;
      this.calculatePrice(index);
    },
    decrement(index) {
      if (this.vmodel.items[index].qty > 0) {
        this.vmodel.items[index].qty =
          parseFloat(this.vmodel.items[index].qty) - 1;
      }
      this.calculatePrice(index);
    },
    repeateAgain() {
      const newItem = {
        itemid: "",
        itemname: "",
        categoryid: "",
        categoryname: "",
        qty: 0,
        price: 0,
      };
      this.vmodel.items = [...this.vmodel.items, newItem];
    },
    removeItem(index) {
      this.vmodel.items.splice(index, 1);
    },

    async createOrder() {
      this.vmodel.subtotal = this.calculateSummary.subTotal;
      this.vmodel.discount = this.discountprice;
      this.vmodel.total = this.calculateSummary.orderTotal;

      this.validated = true;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          orderno: this.vmodel.orderno,
          orderdate: this.vmodel.orderdate,
          memberid: this.vmodel.memberid,
          pickuptimeid: this.vmodel.pickuptimeid,
          promocodeid: this.vmodel.promocodeid,
          promocode: this.vmodel.promocode,
          subtotal: this.vmodel.subtotal,
          discount: this.vmodel.discount,
          total: this.vmodel.total,
          paymentmethod: this.vmodel.paymentmethod,
          items: this.vmodel.items,
          promocodediscount: this.discountprice,
          promocodediscounttype: this.discounttype,
        };
        console.log(payload, 9898);
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/ordermanagement", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "OrderManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/ordermanagement", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "OrderManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getOrderById() {
      APIService.getById("/ordermanagement", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.orderno = response.data.data.orderno;
            this.vmodel.orderdate = DateFormat.formatDate(
              response.data.data.orderdate
            );
            const data = response.data.data;
            this.vmodel.memberid = data.memberid;
            this.vmodel.pickuptimeid = data.pickuptimeid;
            this.vmodel.promocodeid = data.promocodeid;
            this.vmodel.promocode = data.promocode;
            this.discountprice = data.promocodediscount;
            this.discounttype = data.promocodediscounttype;
            this.vmodel.subtotal = data.subtotal;
            this.vmodel.discount = data.discount;
            this.vmodel.total = data.total;
            this.vmodel.paymentmethod = data.paymentmethod;
            this.vmodel.items = data.items;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "OrderManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getMember() {
      APIService.get("/members")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.memberList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getPickupTime() {
      APIService.get("/pickuptime/")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.pickuptimeList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getItem() {
      APIService.get("/itemmenu")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.itemList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getPromocode() {
      let payload = {
        code: this.vmodel.promocode,
      };
      APIService.post("/promontioncode/bycode/", payload)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.promocode = response.data.data.code;
            this.discountprice = response.data.data.discount;
            this.discounttype = response.data.data.type;
            this.vmodel.promocodeid = response.data.data._id;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getItemListDetails(index) {
      this.itemList
        .filter((x) => x.id === this.vmodel.items[index].itemid)
        .map((data) => {
          this.vmodel.items[index].itemname = data.name;
          this.vmodel.items[index].categoryid = data.cetegoryid;
          this.vmodel.items[index].categoryname = data.cetegory;
        });
      this.calculatePrice(index);
    },
    calculatePrice(index) {
      if (this.vmodel.items[index].qty > 0) {
        this.itemList
          .filter((x) => x.id === this.vmodel.items[index].itemid)
          .map((data) => {
            this.vmodel.items[index].price = data.price;
          });
        this.vmodel.items[index].price =
          this.vmodel.items[index].price * this.vmodel.items[index].qty;
      } else {
        this.vmodel.items[index].price = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.disable-text-field) {
  .p-inputtext {
    background: rgba(204, 201, 214, 0.4);
  }
}

::v-deep(.width-dropdown) {
  .p-inputtext {
    width: 186px;
  }
}

.quantity-field {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  border-radius: 8px;
}

.quantity-field .value-button {
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 52px;
  padding: 0;
  background: #f5f2fa;
  outline: none;
  cursor: pointer;
}

.quantity-field .value-button:hover {
  background: #f5f2fa;
}

.quantity-field .value-button:active {
  background: #f5f2fa;
}

.quantity-field .decrease-button {
  margin-right: -4px;
  border-radius: 8px 0 0 8px;
}

.quantity-field .increase-button {
  margin-left: -4px;
  border-radius: 0 8px 8px 0;
  border-left: none;
}

.quantity-field .number {
  display: inline-block;
  text-align: center;
  border: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0px;
  width: 56px;
  height: 52px;
  line-height: 20px;
  font-size: 14px;
  box-sizing: border-box;
  background: white;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  padding-top: 15px;
  padding-right: 5px;
  padding-left: 1px;
  color: #2c2249;
  border-radius: unset;
}

.qty-style {
  width: 36px;
  margin-top: -21px;
  border-radius: unset;
  border-left: none;
  border-right: none;
  padding-left: 11px;
}

.quantity-field .number::selection {
  background: none;
}

.margin-for-list {
  // margin-left: 387px;
  text-align: right;
}

.item-name-width {
  width: 215px;
}

/*
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
*/
</style>