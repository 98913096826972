import { createWebHistory, createRouter } from "vue-router";
import Store from '../store';
import MainLayout from "@/views/main-layout.vue";
import Login from "@/views/login.vue";
import ForgotPassword from "@/views/forgot-password.vue";
import MemberManagement from "@/views/MemberManagement/member-list.vue"
import MemberManagementForm from "@/views/MemberManagement/member-form.vue";
import ItemCategory from "@/views/ItemCategory/item-category-list.vue"
import ItemCategoryForm from "@/views/ItemCategory/item-category-form.vue"
import ItemManagement from "@/views/ItemManagement/item-management-list.vue"
import ItemManagementForm from "@/views/ItemManagement/item-management-form.vue"
import PromotionCode from "@/views/PromotionCode/promotion-code-list.vue"
import PromotionCodeForm from "@/views/PromotionCode/promotion-code-form.vue"
import PickupTime from "@/views/PickupTime/pick-up-time-list.vue"
import PickupTimeForm from "@/views/PickupTime/pick-up-time-form.vue"
import FoodPassManagement from "@/views/FoodPassManagement/food-pass-list.vue"
import FoodPassManagementForm from "@/views/FoodPassManagement/food-pass-form.vue"
import FoodPassReport from "@/views/FoodPassReport/food-pass-report-list.vue"
import OrderManagement from "@/views/OrderManagement/order-management-list.vue"
import OrderManagementForm from "@/views/OrderManagement/order-management-form.vue"
import OrderManagementView from "@/views/OrderManagement/order-management-view.vue"
import PrivacyPolicy from "@/views/PrivacyPolicyPage/privacy-policy.vue"
import TermsAndConditions from "@/views/TermsAndConditionsPage/terms-and-conditions.vue"

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: "Login"
        }
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
        meta: {
            title: "Forgot password"
        }
    },
    {
        component: MainLayout,
        children: [
            {
                path: "/member-management",
                name: "MemberManagement",
                component: MemberManagement,
                meta: {
                    requiresAuth: true,
                    module: 'MemberManagement',
                    title: "Member management"
                },
            },
            {
                path: "/member-management/create",
                name: "MemberManagementCreate",
                component: MemberManagementForm,
                meta: {
                    requiresAuth: true,
                    module: 'MemberManagement',
                    title: "Member Management / Add"
                },
            },
            {
                path: "/member-management/edit/:id",
                name: "MemberManagementEdit",
                component: MemberManagementForm,
                meta: {
                    requiresAuth: true,
                    module: 'MemberManagement',
                    title: "Member Management / Edit"
                },
            },
            {
                path: "/item-category",
                name: "ItemCategory",
                component: ItemCategory,
                meta: {
                    requiresAuth: true,
                    module: 'ItemCategory',
                    title: "Item category"
                },
            },
            {
                path: "/item-category/create",
                name: "ItemCategoryCreate",
                component: ItemCategoryForm,
                meta: {
                    requiresAuth: true,
                    module: 'ItemCategory',
                    title: "Item Category / Add"
                },
            },
            {
                path: "/item-category/edit/:id",
                name: "ItemCategoryEdit",
                component: ItemCategoryForm,
                meta: {
                    requiresAuth: true,
                    module: 'ItemCategory',
                    title: "Item Category / Edit"
                },
            },
            {
                path: "/item-management",
                name: "ItemManagement",
                component: ItemManagement,
                meta: {
                    requiresAuth: true,
                    module: 'ItemManagement',
                    title: "Item management"
                },
            },
            {
                path: "/item-management/create",
                name: "ItemManagementCreate",
                component: ItemManagementForm,
                meta: {
                    requiresAuth: true,
                    module: 'ItemManagement',
                    title: "Item Management / Add"
                },
            },
            {
                path: "/item-management/edit/:id",
                name: "ItemManagementEdit",
                component: ItemManagementForm,
                meta: {
                    requiresAuth: true,
                    module: 'ItemManagement',
                    title: "Item Management / Edit"
                },
            },
            {
                path: "/promotion-code",
                name: "PromotionCode",
                component: PromotionCode,
                meta: {
                    requiresAuth: true,
                    module: 'PromotionCode',
                    title: "Promotion code"
                },
            },
            {
                path: "/promotion-code/create",
                name: "PromotionCodeCreate",
                component: PromotionCodeForm,
                meta: {
                    requiresAuth: true,
                    module: 'PromotionCode',
                    title: "Promotion Code / Add"
                },
            },
            {
                path: "/promotion-code/edit/:id",
                name: "PromotionCodeEdit",
                component: PromotionCodeForm,
                meta: {
                    requiresAuth: true,
                    module: 'PromotionCode',
                    title: "Promotion Code / Edit"
                },
            },
            {
                path: "/pick-up-time",
                name: "PickupTime",
                component: PickupTime,
                meta: {
                    requiresAuth: true,
                    module: 'PickupTime',
                    title: "Pickup time"
                },
            },
            {
                path: "/pick-up-time/create",
                name: "PickupTimeCreate",
                component: PickupTimeForm,
                meta: {
                    requiresAuth: true,
                    module: 'PickupTime',
                    title: "Pickup Time / Add"
                },
            },
            {
                path: "/pick-up-time/edit/:id",
                name: "PickupTimeEdit",
                component: PickupTimeForm,
                meta: {
                    requiresAuth: true,
                    module: 'PickupTime',
                    title: "Pickup Time / Edit"
                },
            },
            {
                path: "/food-pass-management",
                name: "FoodPassManagement",
                component: FoodPassManagement,
                meta: {
                    requiresAuth: true,
                    module: 'FoodPassManagement',
                    title: "Foodpass management"
                },
            },
            {
                path: "/food-pass-management/create",
                name: "FoodPassManagementCreate",
                component: FoodPassManagementForm,
                meta: {
                    requiresAuth: true,
                    module: 'FoodPassManagement',
                    title: "Food Pass Management / Add"
                },
            },
            {
                path: "/food-pass-management/edit/:id",
                name: "FoodPassManagementEdit",
                component: FoodPassManagementForm,
                meta: {
                    requiresAuth: true,
                    module: 'FoodPassManagement',
                    title: "Food Pass Management / Edit"
                },
            },
            {
                path: "/food-pass-report",
                name: "FoodPassReport",
                component: FoodPassReport,
                meta: {
                    requiresAuth: true,
                    module: 'FoodPassReport',
                    title: "Foodpass report"
                },
            },
            {
                path: "/order-management",
                name: "OrderManagement",
                component: OrderManagement,
                meta: {
                    requiresAuth: true,
                    module: 'OrderManagement',
                    title: "Order management"
                },
            },
            {
                path: "/order-management/create",
                name: "OrderManagementCreate",
                component: OrderManagementForm,
                meta: {
                    requiresAuth: true,
                    module: 'OrderManagement',
                    title: "Order Management / Add"
                },
            },
            {
                path: "/order-management/edit/:id",
                name: "OrderManagementEdit",
                component: OrderManagementForm,
                meta: {
                    requiresAuth: true,
                    module: 'OrderManagement',
                    title: "Order Management / Edit"
                },
            },
            {
                path: "/order-management/view/:id",
                name: "OrderManagementView",
                component: OrderManagementView,
                meta: {
                    requiresAuth: true,
                    module: 'OrderManagement',
                    title: "Order Management / View"
                },
            },
            {
                path: "/privacy-policy",
                name: "PrivacyPolicy",
                component: PrivacyPolicy,
                meta: {
                    requiresAuth: true,
                    module: 'PrivacyPolicy',
                    title: "Privacy policy"
                },
            },
            {
                path: "/terms-conditions",
                name: "TermsAndConditions",
                component: TermsAndConditions,
                meta: {
                    requiresAuth: true,
                    module: 'TermsAndConditions',
                    title: "Terms and conditions"
                },
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// router.beforeEach((to) => {
//     Store.commit('setActiveRoute', to.meta.module);

// })

router.beforeEach((to, from, next) => {
    const title = 'FoodPass';
    if (Store.state.timeout == null || (Store.state.timeout != null && Store.state.timeout > new Date())) {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (Store.state.hasLoggedIn) {
                Store.commit('setUserTimeout');
                Store.commit('setActiveRoute', to.meta.module);
                window.document.title = to.meta && to.meta.title ? title + ' - ' + to.meta.title : title;
                next()
                return
            }
            Store.dispatch("logout");
            next('/login')
            return
        } else if (to.name === undefined) {
            if (Store.state.hasLoggedIn) {
                Store.commit('setActiveRoute', to.meta.module);
                window.document.title = to.meta && to.meta.title ? title + ' - ' + to.meta.title : title;
                next()
                return
            }
            Store.dispatch("logout");
            next('/login')
            return
        } else {
            Store.commit('setUserTimeout');
            if (to.name == "Login" && Store.state.hasLoggedIn) {
                Store.commit('setActiveRoute', 'MemberManagement');
                next("/member-management");
                return
            } else {
                window.document.title = to.meta && to.meta.title ? title + ' - ' + to.meta.title : title;
                next()
                return
            }            
        }
    } else {        
        Store.dispatch("logout");
        next("/login");
        return
    }
})

export default router;