<template>
  <div class="ml-8 mr-8 mt-5 mb-5">
    <div class="grid">
      <div class="col-12 food-form-title">{{ title }}</div>
      <div class="food-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="food-form-sub-title">Category Detail</div>
            </div>
            <div class="col-8">
              <InputTextRequire
                v-model="vmodel.category"
                :validation="v$.vmodel.category"
                placeholder="Category"
                sectionClass="field mt-1"
                id="category"
                label="Category"
              />
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="food-form-button"
                  v-if="!isEditMode"
                  @click="createItemCategory"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="food-form-button"
                  v-else
                  @click="createItemCategory"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter div-class="footer-text" />
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import APIService from "@/services/api.service";
import {required} from "@vuelidate/validators";
export default {
  setup(){
    return {v$: useVuelidate()};
  },
  data() {
    return {
      title: "Item Category / Add",
      isEditMode: false,
      vmodel: {
        category: "",
      },
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel:{
        category:{required}
      }
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Item Category / Edit";
      this.isEditMode = true;
      this.getCategoryById();
    }
  },
  methods:{
    async createItemCategory(){
      const isFormCorrect = await this.v$.$validate();
      if(!isFormCorrect) return;
      else {
        let payload = {
          category: this.vmodel.category,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/itemcategory", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "ItemCategory" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/itemcategory", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "ItemCategory" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getCategoryById(){
      APIService.getById("/itemcategory", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.category = response.data.data.category;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    }
  }
};
</script>
