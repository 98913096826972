export default class Items {
    constructor(data = {}) {
        return {
          itemid: data.itemid || '',
          itemname: data.itemname || '',
          categoryid: data.categoryid || '',
          categoryname: data.categoryname || '',
          qty: data.qty || '',
          price: data.price || '',
        }

    }
}