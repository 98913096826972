<template>
  <div class="m-5">
    <DataTable
      :value="users"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="username"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 text-left list-header">Member Management</div>
          <div class="col-6 text-right ml-4">
            <PrimeButton
              label="Add"
              class="add-button mr-3"
              icon="add-icon"
              @click="gotoCreateMember"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="userid" header="User ID" sortable="true"></Column>
      <Column
        field="username"
        header="User"
        sortable="true"
      >
        <template #body="row">
          <div class="grid">
            <div class="col-4 mt-2">
              <img
                :src="getAvatarPath(row.data.avatar)"
                alt=""
                class="img-item"
              />
            </div>
            <div class="col-8 margin-for-username">
              <span>{{ row.data.username }}</span>
            </div>
          </div>
        </template>
      </Column>
      <Column field="email" header="Email" sortable="true"></Column>
      <Column field="createdby" header="Created By" sortable="true"></Column>
      <Column field="createddate" header="Created At" sortable="true"></Column>
      <Column field="action" header="Action" class="col-width">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              label="Edit"
              icon="edit-icon"
              class="edit-button"
              @click="gotoMemberEdit(data.data.id)"
            />
            <PrimeButton
              label="Delete"
              icon="delete-icon"
              class="delete-button"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <MainFooter div-class="footer-text" />
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteMember"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api.service.js";
import MemberFactory from "@/factories/member";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      memberIdForDelete: "",
      showPopup: false,
      users: [
        {
          userid: "1234e5",
          avatar: "",
          username: "mizumo12",
          email: "mizumo12@email.com",
          createdby: "Chris Wong",
          createddate: "2022-03-02 12:15:41",
        },
      ],
    };
  },
  mounted() {
    this.getMemberList();
  },
  methods: {
    gotoMemberEdit(memberId) {
      this.$router.push({
        name: "MemberManagementEdit",
        params: { id: memberId },
      });
    },
    gotoCreateMember() {
      this.$router.push({ name: "MemberManagementCreate" });
    },
    showDeletePopup(memberId) {
      this.memberIdForDelete = memberId;
      this.showPopup = true;
    },
    getAvatarPath(avatarname) {
      return process.env.VUE_APP_PROFILE_IMAGE_BASE_PATH + avatarname;
    },
    deleteMember() {
      APIService.delete(`/members/delete/${this.memberIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getMemberList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    getMemberList() {
      this.users = [];
      APIService.get(
        `/members/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.users = MemberFactory.createFromJsonArray(response.data.data);
        }
      });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getMemberList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getMemberList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getMemberList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>