import ItemManagement from '@/models/itemmanagement'

export default class ItemManagementFactory {
    static createFromJson(json) {
        return new ItemManagement(json)
    }

    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(ItemManagementFactory.createFromJson(item))
        })

        return jsonData
    }
}
