<template>
  <div class="container-footer">
    <div :class="divClass">Grand One Enterprise Limited</div>
  </div>
</template>

<script>
export default {
  props: {
    divClass: {
      type: String,
      default: "footer-text",
    },
  },
};
</script>

<style scoped>
.container-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 16px;
  gap: 8px;

  position: absolute;
  width: -webkit-fill-available !important;
  height: 60px;
  left: 360px;

  /* Grey/10 */

  background: #f5f2fa;
}
</style>