<template>
  <div class="m-5">
    <DataTable
      :value="orders"
      :paginator="true"
      :lazy="true"
      v-model:expandedRows="expandedRows"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="orderno"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
      expandedRowIcon="chevron-up-icon"
      collapsedRowIcon="chevron-down-icon"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 list-header">Order Management</div>
          <div class="col-6 text-right ml-4 list-header">
            <PrimeButton
              label="Add"
              class="add-button mr-3"
              icon="add-icon"
              @click="gotoCreateOrder"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="expand" :expander="true">
        <template #header>
          <span class="p-column-title"></span>
          <button
            class="p-row-toggler p-link header-toggle"
            type="button"
            @click="arrowToggle"
          >
            <span
              :class="{
                'p-row-toggler-icon pi chevron-down-icon': !isToggled,
                'p-row-toggler-icon pi chevron-up-icon': isToggled,
              }"
            ></span>
          </button>
        </template>
      </Column>
      <Column field="orderno" header="Order #" sortable="true"></Column>
      <Column field="orderdate" header="Order Date" sortable="true"></Column>
      <Column field="status" header="Status" sortable="true">
        <template #body="data">
          <Chip
            v-if="data.data.status == 'Confirm'"
            :label="data.data.status"
            class="chip-style-for-order-confirm"
          />
          <Chip
            v-else
            :label="data.data.status"
            class="chip-style-for-order-cancel"
          />
        </template>
      </Column>
      <Column field="pickuptime" header="Pick-up Time" sortable="true"></Column>
      <Column
        field="paymentmethod"
        header="Payment Method"
        sortable="true"
      ></Column>
      <Column field="total" header="Total Price" sortable="true"></Column>
      <Column field="action" header="Action" class="col-width">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              label="Edit"
              icon="edit-icon"
              class="edit-button"
              @click="gotoOrderEdit(data.data.id)"
              v-if="data.data.paymentmethod === 'Stripe'"
            />
            <PrimeButton
              label="Delete"
              icon="delete-icon"
              class="delete-button"
              @click="showDeletePopup(data.data.id)"
              v-if="data.data.paymentmethod === 'Stripe'"
            />
            <PrimeButton
              label="View"
              class="edit-button"
              @click="goToViewOrder(data.data.id)"
              v-if="data.data.paymentmethod === 'Food Pass'"
            />
            <PrimeButton
              v-if="data.data.status !== 'Cancel' && data.data.paymentmethod === 'Stripe'"
              id="cancel"
              label="Cancel"
              icon="cancel-icon"
              class="cancel-button"
              @click="onCancel(data.data.id)"
            />
          </div>
        </template>
      </Column>

      <template #expansion="slotProps">
        <div class="grid">
          <div class="col-8">
            <DataTable :value="slotProps.data.items" class="expand-table">
              <Column field="itemname" header="Item Name"> </Column>
              <Column field="categoryname" header="Category">
                <template #body="row">
                  <Chip
                    :label="row.data.categoryname"
                    class="chip-style-for-order-category"
                  />
                </template>
              </Column>
              <Column field="qty" header="Quantity"></Column>
              <Column field="price" header="Price"></Column>
            </DataTable>
          </div>
          <div class="col-4">
            <Card>
              <template #title> Order Summary </template>
              <template #content>
                <div class="flex">
                  <div class="col-6 card-font">Subtotal</div>
                  <div class="col-6 card-font-digit card-margin-total">
                    ${{ slotProps.data.subtotal.toFixed(1) }}
                  </div>
                </div>
                <div class="flex mt-2">
                  <div class="col-6 card-font">Promotion Code</div>
                  <div class="col-2 card-font-promocode card-margin-promo">
                    {{ slotProps.data.promocode }}
                  </div>
                  <div class="col-2 promo-code-font card-margin-discount">
                    ${{ slotProps.data.discount.toFixed(2) }}
                  </div>
                </div>
                <div class="flex mt-2">
                  <div class="col-6 card-font">Order Total</div>
                  <div class="col-6 card-font-digit card-margin-total">
                    ${{ slotProps.data.total.toFixed(1) }}
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </template>
    </DataTable>
    <MainFooter div-class="footer-text" />
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteOrder"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api.service.js";
import OrderManagementFactory from "@/factories/ordermanagement";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      orderIdForDelete: "",
      showPopup: false,
      isToggled: false,
      expandedRows: [],
      orders: [],
    };
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    gotoOrderEdit(orderId) {
      this.$router.push({
        name: "OrderManagementEdit",
        params: { id: orderId },
      });
    },
    goToViewOrder(orderId) {
      this.$router.push({
        name: "OrderManagementView",
        params: { id: orderId },
      });
    },
    gotoCreateOrder() {
      this.$router.push({ name: "OrderManagementCreate" });
    },
    expandAll() {
      this.expandedRows = this.orders.filter((x) => x.id);
    },
    collapseAll() {
      this.expandedRows = null;
    },
    arrowToggle() {
      if (this.isToggled) {
        this.collapseAll();
      } else {
        this.expandAll();
      }
      return (this.isToggled = !this.isToggled);
    },
    showDeletePopup(orderId) {
      this.orderIdForDelete = orderId;
      this.showPopup = true;
    },
    onCancel(orderId) {
      APIService.get(`/ordermanagement/status/${orderId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getOrderList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    deleteOrder() {
      APIService.delete(`/ordermanagement/delete/${this.orderIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getOrderList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    getOrderList() {
      this.orders = [];
      APIService.get(
        `/ordermanagement/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.orders = OrderManagementFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getOrderList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "orderdate") {
        sortField = "orderdate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getOrderList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getOrderList();
    },
    // arrowToggle() {
    //   if (this.isToggled) {
    //     return 'chevron-down-icon'
    //   } else {
    //     return 'chevron-up-icon'
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";

.header-toggle {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.card-margin-total {
  margin-left: 110px;
}

.card-margin-promo {
  margin-left: -19px;
}

.card-margin-discount {
  margin-left: 84px;
}

::v-deep(.expand-table) {
  .p-datatable-table {
    padding-bottom: 8px;
  }

  .p-datatable-wrapper {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
</style>