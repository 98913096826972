import DateFormat from "@/libs/DateFormat";

export default class PickupTime {
    constructor(data = {}) {
        return {
            id: data.id || '',
            pickuptime: data.pickuptime || '',
            createdby: data.createdby || '',
            createddate: DateFormat.formatDate(data.createddate) || '',
        }

    }
}