<template>
  <div :class="sectionClass">
    <label :for="id" class="form-label">{{ label }}</label>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <span class="calendar-icon"></span>
      </span>
      <Calendar
        inputId="basic"
        :id="id"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        :disabledDays="disabledDays"
        :disabledDates="disabledDates"
        :manualInput="manualInput"
        :class="{
          'p-invalid': validation.$error,
        }"
        :modelValue="modelValue"
        :aria-describedby="`${id}-help`"
        dateFormat="yyyy-mm-dd"
        @date-select="$emit('update:modelValue', formatDate($event))"
      />
    </div>
    <span
      v-if="
        validation.$error && validation.required && validation.required.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.required.$message.replace("Value", this.label) }}
    </span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    validation: {
      type: Object,
      required: true,
    },
    minlength: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledDates: {
      type: Array,
      default: null,
    },
    disabledDays: {
      type: Array,
      default: null,
    },
    manualInput: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },
};
</script>