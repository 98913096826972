<template>
  <div class="m-5">
    <DataTable
      :value="picktime"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="pickuptime"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 list-header">Pick-up Time</div>
          <div class="col-6 text-right ml-4 list-header">
            <PrimeButton
              label="Add"
              class="add-button mr-3"
              icon="add-icon"
              @click="gotoCreatePickTime"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch"/>
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="pickuptime" header="Pick-up Time" sortable="true"></Column>
      <Column field="createdby" header="Created By" sortable="true"></Column>
      <Column field="createddate" header="Created At" sortable="true"></Column>
      <Column field="action" header="Action" class="col-width">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              label="Edit"
              icon="edit-icon"
              class="edit-button"
              @click="gotoPickTimeEdit(data.data.id)"
            />
            <PrimeButton
              label="Delete"
              icon="delete-icon"
              class="delete-button"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <MainFooter div-class="footer-text" />
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deletePickTime"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api.service";
import PickupTimeFactory from "@/models/pickuptime";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      picktimeIdForDelete: "",
      showPopup: false,
      picktime: [],
    };
  },
  mounted() {
    this.getPickTimeList();
  },
  methods: {  
    gotoPickTimeEdit(picktimeId) {
      this.$router.push({ name: "PickupTimeEdit", params: { id: picktimeId } });
    },
    gotoCreatePickTime() {
      this.$router.push({ name: "PickupTimeCreate" });
    },
    showDeletePopup(picktimeId) {
      this.picktimeIdForDelete = picktimeId;
      this.showPopup = true;
    },
    deletePickTime() {
      APIService.delete(`/pickuptime/delete/${this.picktimeIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getPickTimeList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    getPickTimeList() {
      this.picktime=[];
      APIService.get(
        `/pickuptime/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.picktime = PickupTimeFactory.createFromJsonArray(response.data.data);
        }
      });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getPickTimeList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getPickTimeList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getPickTimeList();
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>