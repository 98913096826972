<template>
  <div :class="sectionClass">
    <label :for="id" class="form-label">{{ label }}</label>
    <div class="p-inputgroup">
      <InputText
        :id="id"
        :type="type"
        :placeholder="placeholder"
        :value="modelValue"
        :aria-describedby="`${id}-help`"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <span class="p-inputgroup-addon">
        <span class="percentage-icon"></span>
      </span>
    </div>
    <span
      v-if="
        validation.$error && validation.required && validation.required.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.required.$message.replace("Value", this.label) }}
    </span>
    <span
      v-else-if="
        validation.$error && validation.email && validation.email.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.email.$message }}
    </span>

    <span
      v-else-if="validation.$error && validation.url && validation.url.$invalid"
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.url.$message }}
    </span>

    <span
      v-else-if="validation.$error && validation.min && validation.min.$invalid"
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.min.$message }}
    </span>
    <span
      v-else-if="validation.$error && validation.max && validation.max.$invalid"
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.max.$message }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    validation: {
      type: Object,
      required: true,
    },
    minlength: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    groupLabel: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    }
  },
};
</script>