import PromotionCode from "@/models/promotioncode";

export default class PromotionCodeFactory {
    static createFromJson(json) {
        return new PromotionCode(json)
    }

    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(PromotionCodeFactory.createFromJson(item))
        })

        return jsonData
    }
}