<template>
  <div class="ml-8 mr-8 mt-5 mb-5">
    <div class="grid">
      <div class="col-12 food-form-title">{{ title }}</div>
      <div class="food-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="food-form-sub-title">Item Detail</div>
            </div>
            <div class="col-8">
              <div class="field">
                <label for="photo" class="form-label">Image</label>
                <div
                  class="photo-section cursor-pointer"
                  @click="openFileUpload"
                >
                <img
                    v-if="vmodel.image !== ''"
                    :src="getImagePath(vmodel.image)"
                    alt=""
                    class="upload-img"
                    height="160"
                    width="160"
                  />
                  <span v-if="vmodel.image == ''" class="upload-icon"></span>
                  <div v-if="vmodel.image == ''" class="upload-text">
                    Upload a file
                  </div>
                  <div v-if="vmodel.image == ''" class="upload-text-small">
                    Maximum file size: 1 MB
                  </div>
                </div>
                <FileUpload
                  ref="photo"
                  name="photo"
                  accept=".png, .jpg, .jpeg"
                  @select="onAdvancedUpload($event)"
                  v-show="false"
                  :aria-describedby="`photo-help`"
                />
              </div>
              <div
                  v-if="
                    v$.vmodel.image.$error &&
                    v$.vmodel.image.required &&
                    v$.vmodel.image.required.$invalid
                  "
                  class="p-error"
                >
                  {{
                    v$.vmodel.image.required.$message.replace("Value", "Image")
                  }}
                </div>
              <DropDownRequire
                v-model="vmodel.categoryid"
                :validation="v$.vmodel.categoryid"
                placeholder="Select Category"
                sectionClass="field mt-1"
                id="category"
                label="Category"
                :options="categoryList"
                optionLabel="category"
                optionValue="id"
              />
              <TextInput
                v-model="vmodel.name"
                :validation="v$.vmodel.name"
                placeholder="Item Name"
                sectionClass="field mt-1"
                id="itemName"
                label="Item Name"
              />
              <TextInput
                v-model="vmodel.detail"
                :validation="v$.vmodel.detail"
                placeholder="Item Details"
                sectionClass="field mt-1"
                id="itemDetail"
                label="Item Details"
                maxlength="500"
              />
              <InputGroup
                v-model="vmodel.price"
                :validation="v$.vmodel.price"
                placeholder="Price"
                sectionClass="field mt-1"
                id="price"
                :min="1"
                label="Price"
              />
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="food-form-button"
                  v-if="!isEditMode"
                  @click="createItemMenu"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="food-form-button"
                  v-else
                  @click="createItemMenu"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter div-class="footer-text" />
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api.service.js";
import { required, maxLength, minValue } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      title: "Item (Menu) Management / Add",
      isEditMode: false,
      photo: "",
      categoryList: [],
      vmodel: {
        image: "",
        categoryid: "",
        name: "",
        detail: "",
        price: 1,
      },
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel: {
        image: { required },
        categoryid: { required, max: maxLength(500) },
        name: { required, max: maxLength(500) },
        detail: { required, max: maxLength(500) },
        price: { required, minValue: minValue(1) },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Item (Menu) Management / Edit";
      this.isEditMode = true;
      this.getItemMenuById();
    }
    this.getCategory();
  },
  methods: {
    openFileUpload() {
      this.$refs.photo.choose();
    },
    async createItemMenu() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          image: this.vmodel.image,
          categoryid: this.vmodel.categoryid,
          name: this.vmodel.name,
          detail: this.vmodel.detail,
          price: this.vmodel.price,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/itemmenu", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "ItemManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/itemmenu", payload)
            .then((response) => {
              console.log(1);
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "ItemManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getItemMenuById() {
      APIService.getById("/itemmenu", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.image = response.data.data.image;
            this.vmodel.categoryid = response.data.data.categoryid;
            this.vmodel.name = response.data.data.name;
            this.vmodel.detail = response.data.data.detail;
            this.vmodel.price = response.data.data.price;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "ItemManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getCategory() {
      APIService.get("/itemcategory")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.categoryList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getImagePath(name) {
      return process.env.VUE_APP_MENU_IMAGE_BASE_PATH + name;
    },
    onAdvancedUpload(fileObj) {
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid image",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image size should be less then 1 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        let formData = new FormData();
        formData.append("file", fileObj.files[0]);
        APIService.post("/itemmenu/menu", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              // console.log(response.data, 'response.data.data.name');
              this.vmodel.image = response.data.data.name;
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.photo.clear();
          });
      }
    },
  },
};
</script>

<style>
.photo-section {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 8px;

  width: 240px;
  height: 160px;

  /* White */

  background: #ffffff;
  /* Grey/20 */

  border: 1px solid #ccc9d6;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.upload-text {
  width: 96px;
  height: 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Primary/Tint */

  color: #533d90;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.upload-text-small {
  width: 158px;
  height: 20px;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: -8px;
  /* identical to box height, or 143% */

  /* Grey/30 */

  color: #87838f;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>