import OrderManagement from '@/models/ordermanagement'

export default class OrderManagementFactory {
    static createFromJson(json) {
        return new OrderManagement(json)
    }

    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(OrderManagementFactory.createFromJson(item))
        })

        return jsonData
    }
}
