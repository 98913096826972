<template>
  <div class="sidebar">
    <div class="logo">
      <span><img src="../../assets/images/logo.svg" /></span>
    </div>
    <div class="menu">
      <router-link
        :to="{ name: 'MemberManagement' }"
        id="MemberManagement"
        :class="
          $store.state.activeRoute === 'MemberManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="member-icon"></i>
        <span class="menu-text">Member Management</span>
      </router-link>

      <router-link
        :to="{ name: 'ItemCategory' }"
        id="ItemCategory"
        :class="
          $store.state.activeRoute === 'ItemCategory'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="category-icon"></i>
        <span class="menu-text">Item Category</span>
      </router-link>

      <router-link
        :to="{ name: 'ItemManagement' }"
        id="ItemManagement"
        :class="
          $store.state.activeRoute === 'ItemManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="item-icon"></i>
        <span class="menu-text">Item (Menu) Management</span>
      </router-link>

      <router-link
        :to="{ name: 'PromotionCode' }"
        id="PromotionCode"
        :class="
          $store.state.activeRoute === 'PromotionCode'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="promotion-icon"></i>
        <span class="menu-text">Promotion Code</span>
      </router-link>

      <router-link
        :to="{ name: 'PickupTime' }"
        id="PickupTime"
        :class="
          $store.state.activeRoute === 'PickupTime'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="pickup-icon"></i>
        <span class="menu-text">Pick-up Time</span>
      </router-link>

      <router-link
        :to="{ name: 'FoodPassManagement' }"
        id="FoodPassManagement"
        :class="
          $store.state.activeRoute === 'FoodPassManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="foodpass-icon"></i>
        <span class="menu-text">Food Pass Management</span>
      </router-link>

      <router-link
        :to="{ name: 'FoodPassReport' }"
        id="FoodPassReport"
        :class="
          $store.state.activeRoute === 'FoodPassReport'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="report-icon"></i>
        <span class="menu-text">Food Pass Report</span>
      </router-link>

      <router-link
        :to="{ name: 'OrderManagement' }"
        id="OrderManagement"
        :class="
          $store.state.activeRoute === 'OrderManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="order-icon"></i>
        <span class="menu-text">Order Management</span>
      </router-link>

      <div
        class="menu-item cursor-pointer"
        @click="visibleDDMenu = !visibleDDMenu"
      >
        <i class="cms-icon"></i>
        <span class="menu-text">CMS Page Management</span>
        <i
          :class="{
            ' chevron-down-icon': !visibleDDMenu,
            ' chevron-up-icon': visibleDDMenu,
          }"
        ></i>
      </div>
      <router-link
        v-if="visibleDDMenu"
        :to="{ name: 'PrivacyPolicy' }"
        id="PrivacyPolicy"
        :class="
          $store.state.activeRoute === 'PrivacyPolicy'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <span class="menu-text">Privacy Policy Page</span>
      </router-link>

      <router-link
        v-if="visibleDDMenu"
        :to="{ name: 'TermsAndConditions' }"
        id="TermsAndConditions"
        :class="
          $store.state.activeRoute === 'TermsAndConditions'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <span class="menu-text">Terms & Conditions Page</span>
      </router-link>

    </div>
    <div class="menu-footer">
      <div class="user-info">
        <i class="avatar-icon"></i>
        <span class="user-info-text">{{ $store.state.userInfo.username }}</span>
      </div>

      <button class="btn-logout cursor-pointer" @click="logout">
        <span class="btn-logout-text">LOGOUT</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarMenu",
  data() {
    return {
      // username: "chantaiman12345",
      visibleLeft: true,
      visibleDDMenu: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/sidebar.css";

.dropdown-list-padding {
  padding-left: 57px;
}
</style>