<template>
  <div class="m-5">
    <DataTable
      :value="foodpass"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="order"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 list-header">Food Pass Report</div>
          <div class="col-6 text-right ml-4 list-header">
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="orderid" header="Order #" sortable="true"></Column>
      <Column field="createddate" header="Order Date" sortable="true"></Column>
      <Column field="username" header="User" sortable="true"></Column>
      <Column field="phoneno" header="Phone" sortable="true"></Column>
      <Column field="foodpassname" header="Food Pass" sortable="true"></Column>
      <Column field="totalqty" header="Meal Qty" sortable="true"></Column>
      <Column field="redeemqty" header="Redeemed Qty" sortable="true"></Column>
      <Column field="remainingqty" header="Remaining Qty" sortable="true"></Column>
      <Column field="price" header="Total Price" sortable="true"></Column>
      <Column
        field="paymentmethod"
        header="Payment Method"
        sortable="true"
      ></Column>
      <Column field="expirydate" header="Expiry Date" sortable="true"></Column>
    </DataTable>
    <MainFooter div-class="footer-text" />
  </div>
</template>

<script>
import APIService from "@/services/api.service.js";
import FoodPassReportFactory from "@/factories/foodpassreport";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "createddate",
      sortDir: "dsc",
      foodpass: [],
    };
  },
  mounted() {
    this.getFoodPassReport();
  },
  methods: {
    getFoodPassReport() {
      this.foodpass = [];
      APIService.get(
        `/foodpassforuser/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.foodpass = FoodPassReportFactory.createFromJsonArray(response.data.data);
        }
      });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getFoodPassReport();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getFoodPassReport();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getFoodPassReport();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>