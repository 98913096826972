<template>
  <div :class="sectionClass">
    <label :for="id" :class="labelClass">{{ label }}</label>
    <InputText
      :id="id"
      :type="type"
      :value="modelValue"
      :disabled="disabled"
      :placeholder="placeholder"
      :class="{
        'p-invalid':
          (validated && customfield && validation) ||
          (validation && validation.$error),
      }"
      :aria-describedby="`${id}-help`"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <span
      v-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.required.$invalid &&
          validation.required)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{
        validated && customfield && validation
          ? validation.$message.replace("Value", this.label)
          : validation.required.$message.replace("Value", this.label)
      }}
    </span>
    <span
      v-else-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.email.$invalid &&
          validation.email)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.email.$message }}
    </span>

    <span
      v-else-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.url.$invalid &&
          validation.url)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.url.$message }}
    </span>
    <span
      v-else-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.min.$invalid &&
          validation.min)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.min.$message }}
    </span>
    <span
      v-else-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.max.$invalid &&
          validation.max)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.max.$message }}
    </span>
    <span
      v-else-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.numeric.$invalid &&
          validation.numeric)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.numeric.$message }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    labelClass: {
      type: String,
      default: "form-label",
    },
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    validation: {
      type: Object,
      required: true,
    },
    minlength: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    customfield: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
  },
};
</script>